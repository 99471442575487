
.a-sections{
    cursor: pointer;
}
.sectionone{
    margin-top: 30px;
}

.section1-image {
width: 100%;
height: auto;
object-fit: cover; 
}

  .carousel{
    z-index: 1;
  }

.section1-image-name{
    position: relative;
}

.section1-name{
    position:absolute;
    top: 5px;
    left: 5px;
    padding: 10px 20px;
    z-index: 1;
    color: #fff;
    font-size: 25px;
    font-weight: 700;
    margin: 0;
    background-color: rgba(0, 0, 0, 0.1);
}


@media screen and (max-width: 768px) {
    .section1-image {
        height: 220px;
    }
    .sectionone{
        margin-top: 10px;
    }
    .section1-name{
        position:absolute;
        /* top: 0;
        left:0; */
        z-index: 1;
        color: #fff;
        font-size: 18px;
        font-weight: 600;
    }
}
